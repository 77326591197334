/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-async-promise-executor */
import { Fetch } from '../fetch';
import { ResponseFailure, ResponseSuccess } from '../responses';
import { ApiURL } from '../util-url';

export const getCountryService = (filter?: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(
			ApiURL(`api/common/locations/countries?${filter || ''}`),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getStateService = ({
	countryId
}: {
	countryId: string;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(
			ApiURL(
				`api/common/locations/states?filter={"countryId":ObjectId("${countryId}")}&limit=1000&fields=["stateCode", "name", "_id", "extras"]`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getCityService = ({
	stateId
}: {
	stateId: string;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(
			ApiURL(
				`api/common/locations/cities?filter={"stateId":ObjectId("${stateId}")}&limit=10000&fields=["_id", "name", "extras"]`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getCitiesByCountryService = ({
	countryId,
	filter
}: {
	countryId: string;
	filter: string;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(
			ApiURL(`api/common/locations/cities/${countryId}?filter=${filter}`),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const userWaitingList = (): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(ApiURL('api/common/user-wait-list'), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const addMeWaitingList = (appId: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({ appId })
		};
		fetch(ApiURL('api/common/user-wait-list'), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const removeMeWaitingList = (appId: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({ appId })
		};
		fetch(ApiURL('api/common/user-wait-list'), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getAppDataInfoService = (id: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(
			ApiURL(`api/common/apps?filter={"_id": new ObjectId("${id}")}`),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

interface IPartnerIntegrationOauthService {
	partnerId: string;
	code: string;
	appId?: string;
	clientId?: string;
	clientSecret?: string;
	authUrlCallback?: string;
}
export const getPartnerIntegrationOauthService = ({
	partnerId,
	code,
	clientId,
	clientSecret,
	appId,
	authUrlCallback
}: IPartnerIntegrationOauthService): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				partnerId,
				code,
				clientId,
				clientSecret,
				appId,
				authUrlCallback
			})
		};
		fetch(
			ApiURL('/api/common/partners/integration/access-token'),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getPartnerIntegrationsService = ({
	propertyId,
	filter
}:
	| { propertyId: string; filter?: string }
	| { propertyId?: string; filter: string }): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(
			ApiURL(
				`/api/common/partners/integrations?filter=${
					propertyId
						? `{"propertyId":new ObjectId("${propertyId}")}`
						: filter
				}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getPropertyIntegrationsService = ({
	propertyId,
	filter
}: {
	propertyId?: string;
	filter?: string;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		if (propertyId) {
			if (propertyId.includes(':')) {
				filter = propertyId;
				propertyId = undefined;
			}
		}
		fetch(
			ApiURL(
				`/api/common/property-integrations?filter=${
					propertyId
						? `{"propertyId":new ObjectId("${propertyId}")}`
						: filter
				}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getPropertyIntegrationPublicService = ({
	propertyId,
	appId,
	partnerIntegrationId
}: {
	propertyId: string;
	appId: string;
	partnerIntegrationId: string;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		};

		fetch(
			ApiURL(
				`/api/common/property-integrations?filter=${`{"propertyId":new ObjectId("${propertyId}"), "appId":new ObjectId("${appId}"), "partnerIntegrationId": new ObjectId("${partnerIntegrationId}")}&fields=["_id","propertyPartner", "extras", "property._id", "property.location", "property.currency","property.timezone"]`}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getPropertyIntegrationsByPropertyPublicService = ({
	propertyId
}: {
	propertyId: string;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		};

		fetch(
			ApiURL(
				`/api/common/property-integrations?filter=${`{"propertyId":new ObjectId("${propertyId}")}&fields=["_id","propertyPartner", "extras", "property._id", "property.location", "property.currency","property.timezone", "partnerIntegrationId"]`}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const createPropertyIntegrationService = (
	data: any,
	masterKeys?: boolean
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};
		fetch(
			ApiURL(
				`/api/common/property-integrations${
					masterKeys ? '/master-keys' : ''
				}`
			),
			requestOptions
		)
			.then(async (response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const deletePropertyIntegrationService = (id: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(ApiURL(`/api/common/property-integrations/${id}`), requestOptions)
			.then(async (response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const pathPropertyIntegrationService = (
	id: string,
	data: any,
	updateMode?: any,
	arrayFilters?: any
): Promise<any> =>
	new Promise((resolve, reject) => {
		const filters = `filter=${JSON.stringify({
			arrayFilters,
			updateMode
		})}`;
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};
		fetch(
			ApiURL(
				`/api/common/property-integrations/${id}${
					filters ? `?${filters}` : ''
				}`
			),
			requestOptions
		)
			.then(async (response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const editPropertyIntegrationService = (
	id: string,
	data: any,
	updateMode?: any,
	arrayFilters?: any
): Promise<any> =>
	new Promise((resolve, reject) => {
		const filters = `filter=${JSON.stringify({
			arrayFilters,
			updateMode
		})}`;
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};
		fetch(
			ApiURL(
				`/api/common/property-integrations/${id}${
					filters ? `?${filters}` : ''
				}`
			),
			requestOptions
		)
			.then(async (response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const putPropertyIntegrationService = (
	id: string,
	data: any
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};
		fetch(ApiURL(`/api/common/property-integrations/${id}`), requestOptions)
			.then(async (response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const validateCredentialsService = (
	partnerId: string,
	mode: string,
	accessToken: string,
	apiUrl?: string
): Promise<any> =>
	new Promise((resolve, reject) => {
		const at = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${at}`
			},
			body: JSON.stringify({
				mode,
				partnerIntegrationId: partnerId,
				accessToken,
				apiUrl
			})
		};
		fetch(
			ApiURL(`/api/common/property-integration-validate`),
			requestOptions
		)
			.then(async (response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const propertyIntegrationsIsCredentialsService = (
	clientId: string,
	clientSecret: string
): Promise<any> =>
	new Promise((resolve, reject) => {
		const at = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${at}`
			},
			body: JSON.stringify({ clientId, clientSecret })
		};
		fetch(
			ApiURL(`/api/common/property-integrations-is-credentials`),
			requestOptions
		)
			.then(async (response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getPropertyService = (propertyId: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(
			ApiURL(
				`/api/common/property?filter={"_id":new ObjectId("${propertyId}")}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getPropertyPublicService = (propertyId: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		};
		fetch(
			ApiURL(
				`/api/common/property?filter={"_id":new ObjectId("${propertyId}")}&fields=["_id","name","location","currency","timezone"]`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getPropertybyFilterPublicService = (
	filter: string
): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		};
		fetch(
			ApiURL(
				`/api/common/property?filter=${filter}&fields=["_id","name","location","currency","timezone"]`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
export const pathPropertyService = (
	propertyId: string,
	data: any
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};
		fetch(ApiURL(`/api/common/property/${propertyId}`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const createFieldMappingService = (data: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};
		fetch(ApiURL(`/api/common/field-mapping`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const updateFieldMappingService = (
	id: string,
	data: any,
	filters?: string
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};
		fetch(
			ApiURL(
				`/api/common/field-mapping/${id}${filters ? `?${filters}` : ''}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const removeFieldMappingService = (id: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(ApiURL(`/api/common/field-mapping/${id}`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getFieldMappingService = (filter: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(
			ApiURL(`/api/common/field-mapping?filter=${filter}`),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getRefreshFieldMappingService = (id: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(ApiURL(`/api/common/field-mapping/sync/${id}`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchGetLogs = (data: any): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		const sort = data.sort;
		const filter = data.filter;
		delete data.sort;
		delete data.filter;
		const params = new URLSearchParams(data as any).toString();

		const url = ApiURL(
			`/api/common/logs?` + params + `&sort=${sort}&filter=${filter}`
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getUrlImages = (data: any[]): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({ data })
		};

		fetch(ApiURL(`/api/common/files/signed-url/bulk`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getUrlImage = (data: {
	url: string;
	expiresIn: number;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(ApiURL(`/api/common/files/signed-url`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getFileUrlTmp = (data: {
	key: string;
	expiresIn: number;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(
			ApiURL(`/api/common/files/download?mode=url&key=${data.key}`),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const patchCity = (id: string, data: any, filters?: string) =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(
			ApiURL(
				`api/common/locations/cities/${id}${
					filters ? `?${filters}` : ''
				}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getApps = (filter: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(ApiURL(`api/common/apps?${filter || {}}`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getPartners = (filter: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(
			ApiURL(`api/common/partners/integrations?${filter || {}}`),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getCurrencyRates = ({
	filter,
	currencyCode
}: {
	filter: string;
	currencyCode?: string;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(
			ApiURL(
				`api/common/currency-rates${
					currencyCode ? `/${currencyCode}` : ''
				}?${filter ? `filter=${filter}` : ''}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

interface extras {
	slug: string;
	value: string;
}
export interface ICreateSchelude {
	partnerIntegrationId: string;
	propertyId: string;
	appId: string;
	dateFrom?: string;
	type?: string;
	extras?: extras[];
}
export const createSchedule = (data: ICreateSchelude): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		const url = ApiURL(`/api/common/schedules`);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getSchedulesService = (options: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		const params = new URLSearchParams(options).toString();
		const url = ApiURL(`/api/common/schedules?${params}`);
		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getPropertyIntegrationExtraPublicService = (
	id: string,
	filter?: string
): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		};

		fetch(
			ApiURL(
				`/api/common/property-integrations/${id}/extras${
					filter ? `?filter=${filter}` : ''
				}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getTermsAndConditionsService = (options: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		const params = new URLSearchParams(options).toString();
		const url = ApiURL(`/api/common/terms-and-conditions?${params}`);
		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const postTermsAndConditionsService = (options: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				appId: options.appId,
				propertyId: options.propertyId,
				description: options.description,
				rawDescription: options.rawDescription
			})
		};
		const url = ApiURL(`/api/common/terms-and-conditions`);
		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const putTermsAndConditionsService = (options: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'PUT',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				appId: options.appId,
				propertyId: options.propertyId,
				description: options.description,
				rawDescription: options.rawDescription
			})
		};
		const url = ApiURL(`/api/common/terms-and-conditions/${options._id}`);
		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const deleteTermsAndConditionsService = (options: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		const url = ApiURL(`/api/common/terms-and-conditions/${options._id}`);
		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getFormService = (options: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		const url = ApiURL(`/api/common/form?${options}`);
		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const postCustomFormService = (options: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(options)
		};
		const url = ApiURL(`/api/common/form`);
		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

interface putCustomFormsServiceProp {
	_id: string;
	data: any;
	updateMode?: 'pull' | 'push';
}
export const putCustomFormsService = (
	options: putCustomFormsServiceProp
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'PUT',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(options.data)
		};
		const url = ApiURL(
			`/api/common/form/${options._id}?filter=${JSON.stringify({
				updateMode: options.updateMode
			})}`
		);
		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const deleteCustomFormService = (id: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		const url = ApiURL(`/api/common/form/${id}`);
		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const postOffboardingService = (params: {
	propertyId: string;
	appId: string;
	partnerIntegrationId: string;
}): Promise<any> => Fetch('POST', '/api/common/offboarding', { data: params });

export const getPlaylistsService = (
	params: any
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('get', 'api/common/playlists', params);

export const getPlaylistVideoService = (params: {
	pathParams: { _id: string };
}): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('get', 'api/common/playlists/:_id', params);

export const createQuotationService = (data: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		};

		fetch(ApiURL(`api/common/quotation`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
