/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	RequestCreateIntentPaymentHold,
	RequestCreateIntentPaymentHoldSecure3ds,
	RequestCreatePaymentHold,
	RequestFinOneProduct,
	RequestFindAllFieldMapping,
	RequestFindAllUpsellingBalance,
	RequestFindAllUpsellingBankAccounts,
	RequestFindAllUpsellingPayment,
	RequestFindAllUpsellingProducts,
	RequestFindAllUpsellingPurchaseIntent,
	getTTLockListService,
	getTemplateService,
	getTemplateServiceProps,
	getWhatsappDisconnectService,
	getWhatsappMeService,
	getWhatsappMessageService,
	postPolicySavePayment,
	postTTLockTokenService,
	postWhatsappConnectService,
	smartlockListService,
	smartlockListServiceProps
} from '@hospy/util-api';
import { RequestCreate, RequestFindAll } from '@hospy/util-api/requests';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	IPolicyPayment,
	UpsellingBankAccountsProps,
	UpsellingProductsProps,
	UpsellingPurchaseIntentProps
} from './interfaces';

export const getWhatsappMe = createAsyncThunk(
	'get/whatsapp-me',
	async (params: {
		propertyId: string;
		appId: string;
		partnerIntegrationId: string;
		partnerIntegrationIdTo: string;
	}) => {
		const { data } = await getWhatsappMeService(params);
		return data;
	}
);

export const postWhatsappConnect = createAsyncThunk(
	'get/whatsapp-connect',
	async (body: {
		propertyId: string;
		appId: string;
		partnerIntegrationId: string;
		partnerIntegrationIdTo: string;
	}) => {
		const { data } = await postWhatsappConnectService(body);
		return data;
	}
);

export const postWhatsappDisconnect = createAsyncThunk(
	'get/whatsapp-disconnect',
	async (params: {
		propertyId: string;
		appId: string;
		partnerIntegrationId: string;
		partnerIntegrationIdTo: string;
	}) => {
		const { data } = await getWhatsappDisconnectService(params);
		return data;
	}
);

export const postWhatsappMessage = createAsyncThunk(
	'get/whatsapp-message',
	async (body: {
		propertyId: string;
		contact: string;
		appId: string;
		partnerIntegrationId: string;
		partnerIntegrationIdTo: string;
	}) => {
		const { data } = await getWhatsappMessageService({
			message: 'Hola, este es un mensaje de prueba desde Hospy.',
			...body
		});
		return data;
	}
);

export const getCheckinHostTemplates = createAsyncThunk(
	'get/checkin-host-templates',
	async (props: getTemplateServiceProps, { rejectWithValue }) => {
		try {
			const { data, errors } = await getTemplateService(props);
			if (errors) return rejectWithValue(errors);
			return data;
		} catch (error) {
			console.error({ error });
			return rejectWithValue(error);
		}
	}
);

export const chPostTTLockToken = createAsyncThunk(
	'ch/get-ttlock-token',
	async (d: any, { rejectWithValue }) => {
		try {
			const { data, errors } = await postTTLockTokenService(d);
			if (errors) return rejectWithValue(errors);
			return data;
		} catch (error) {
			console.error({ error });
			return rejectWithValue(error);
		}
	}
);

export const chGetTTLockList = createAsyncThunk(
	'ch/get-ttlock-list',
	async ({ id, page }: { id: string; page: number }, { rejectWithValue }) => {
		try {
			const { data, errors } = await getTTLockListService(id, page);
			if (errors) return rejectWithValue(errors);
			return data;
		} catch (error) {
			console.error({ error });
			return rejectWithValue(error);
		}
	}
);

export const ThunkCheckInHostFieldMapping = createAsyncThunk(
	`check-in-host/field-mapping`,
	async (params: RequestFindAll, { rejectWithValue }) => {
		const response = await RequestFindAllFieldMapping(params);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);

export const ThunkCreateIntentPaymentHold = createAsyncThunk(
	`check-in-host/create-intent-payment-hold`,
	async (params: RequestCreate, { rejectWithValue }) => {
		const response = await RequestCreateIntentPaymentHold(params);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);

export const ThunkCreateIntentPaymentHoldSecure3ds = createAsyncThunk(
	`check-in-host/create-intent-payment-hold-secure-3ds`,
	async (params: RequestCreate, { rejectWithValue }) => {
		const response = await RequestCreateIntentPaymentHoldSecure3ds(params);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);

export const ThunkCreatePaymentHold = createAsyncThunk(
	`check-in-host/create-payment-hold`,
	async (params: RequestCreate, { rejectWithValue }) => {
		const response = await RequestCreatePaymentHold(params);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);

export const ThunkCheckInUpsellingProducts = createAsyncThunk(
	`check-in-host/upselling-products`,
	async ({ propertyId }: { propertyId: string }, { rejectWithValue }) => {
		const response = await RequestFindAllUpsellingProducts(propertyId);
		if (response.statusCode === 200) return response.data;
		return rejectWithValue(response);
	}
);

export const ThunkMakePolicyPayment = createAsyncThunk(
	`policy/makePolicyPayment`,
	async (
		{
			payment
		}: {
			payment: IPolicyPayment;
		},
		{ rejectWithValue }
	) => {
		const response = await postPolicySavePayment(payment);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);

export const ThunkCheckInFinOneProduct = createAsyncThunk(
	'common/get-one-product',
	async (params: { _id: string }, { rejectWithValue }) => {
		try {
			const response = await RequestFinOneProduct({
				pathParams: params
			});
			if (response.status === 'success') return response;
			return rejectWithValue(response);
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);

export const ThunkCheckInFindAllUpsellingPayment = createAsyncThunk(
	`check-in-host/find-all-upselling-payment`,
	async (props: UpsellingProductsProps, { rejectWithValue }) => {
		try {
			const response = await RequestFindAllUpsellingPayment(props);

			if (response.errors) {
				return rejectWithValue(response.errors);
			}

			if (response.statusCode === 500) {
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);
			}
			return response;
		} catch (error: any) {
			console.error('ThunkCheckInFindAllUpsellingPayment: ', { error });
			return rejectWithValue(error);
		}
	}
);

export const ThunkCheckInFindAllUpsellingPurchaseIntent = createAsyncThunk(
	`check-in-host/find-all-upselling-purchase-intent`,
	async (props: UpsellingPurchaseIntentProps, { rejectWithValue }) => {
		try {
			const response = await RequestFindAllUpsellingPurchaseIntent(props);

			if (response.errors) {
				return rejectWithValue(response.errors);
			}

			if (response.statusCode === 500) {
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);
			}
			return response;
		} catch (error: any) {
			console.error('ThunkCheckInFindAllUpsellingPayment: ', { error });
			return rejectWithValue(error);
		}
	}
);

export const ThunkCheckInFindAllUpsellingBalance = createAsyncThunk(
	`check-in-host/find-all-upselling-balance`,
	async (props: UpsellingProductsProps, { rejectWithValue }) => {
		try {
			const response = await RequestFindAllUpsellingBalance(props);

			if (response.errors) {
				return rejectWithValue(response.errors);
			}

			if (response.statusCode === 500) {
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);
			}
			return response;
		} catch (error: any) {
			console.error('ThunkCheckInFindAllUpsellingbalance: ', { error });
			return rejectWithValue(error);
		}
	}
);

export const ThunkCheckInFindAllUpsellingBankAccounts = createAsyncThunk(
	`check-in-host/find-all-upselling-bank-accounts`,
	async (props: UpsellingBankAccountsProps, { rejectWithValue }) => {
		try {
			const response = await RequestFindAllUpsellingBankAccounts(props);

			if (response.errors) {
				return rejectWithValue(response.errors);
			}

			if (response.statusCode === 500) {
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);
			}
			return response;
		} catch (error: any) {
			console.error('ThunkCheckInFindAllUpsellingbankAccounts: ', {
				error
			});
			return rejectWithValue(error);
		}
	}
);

export const ThunkCheckInFindAllSmartLockList = createAsyncThunk(
	'check-in/find-all-smart-lock-list',
	async (props: smartlockListServiceProps, { rejectWithValue }) => {
		try {
			const { data, errors } = await smartlockListService(props);
			if (errors) return rejectWithValue(errors);
			return data;
		} catch (error) {
			console.error({ error });
			return rejectWithValue(error);
		}
	}
);
